import React from "react";
import styled from "styled-components";
import { EmptyStar } from "@/assets/Icons/EmptyStar";
import { FilledStar } from "@/assets/Icons/FilledStar";
import { HalvedStar } from "@/assets/Icons/HalvedStar";

function StarIcon({
  order,
  value,
  size,
}: {
  order: number;
  value: number;
  size: number;
}) {
  /* Possible values for diff:
    - equal to -0.5 -> Halved Star
    - negative -> Empty Star 
    - positive -> Filled Star
    - equal to 0 -> Filled Star
  */
  const diff = value - order;
  if (diff === -0.5) {
    return <HalvedStar size={size} />;
  }

  if (diff < 0) {
    return <EmptyStar size={size} />;
  }

  return <FilledStar size={size} />;
}

interface DisplayStarsRatingProps {
  value?: number;
  size?: number;
}

const DisplayStarsRating = ({
  value = 0,
  size = 30,
}: DisplayStarsRatingProps) => {
  return (
    <StarsRow>
      <StarIcon value={value} order={1} size={size} />
      <StarIcon value={value} order={2} size={size} />
      <StarIcon value={value} order={3} size={size} />
      <StarIcon value={value} order={4} size={size} />
      <StarIcon value={value} order={5} size={size} />
    </StarsRow>
  );
};

const StarsRow = styled.div`
  display: flex;
  line-height: 0;
  svg {
    display: inline;
  }
`;

export default DisplayStarsRating;
