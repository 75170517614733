const ShoppingCart = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M 11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2 L 11 4 L 9 4 A 1.0001 1.0001 0 1 0 9 6 L 11 6 L 11 8 A 1.0001 1.0001 0 1 0 13 8 L 13 6 L 15 6 A 1.0001 1.0001 0 1 0 15 4 L 13 4 L 13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062 L 2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719 L 3.0839844 4.0039062 L 3.0859375 4.0039062 L 6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516 L 5.1816406 13.822266 C 4.3432895 15.161013 5.3626785 17 6.9414062 17 L 18 17 A 1.0001 1.0001 0 1 0 18 15 L 6.9414062 15 C 6.8301342 15 6.8173041 14.978071 6.8769531 14.882812 L 8.0527344 13 L 15.521484 13 C 16.246284 13 16.917475 12.604805 17.269531 11.970703 L 21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031 L 15.521484 11 L 8.1660156 11 L 4.9316406 3.2324219 C 4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062 L 3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z"
        fill="currentColor"
      />
    </svg>
  );
};

export { ShoppingCart };
