const SalePriceTagIcon = ({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6657 2C8.31236 2 7.97436 2.13933 7.72436 2.38933C6.36437 3.74933 1.89636 8.218 1.72703 8.39333C1.20036 8.91333 1.20036 9.75333 1.72703 10.2733C2.3337 10.8867 4.94036 13.4933 5.72703 14.2733C6.24703 14.8 7.08703 14.8 7.60703 14.2733C7.78236 14.104 12.251 9.636 13.611 8.276C13.861 8.026 14.0004 7.688 14.0004 7.334V3.33333C14.0004 2.59667 13.4037 2 12.667 2H8.6657ZM7.6737 5.33333C8.22036 5.33333 8.66703 5.78 8.66703 6.32667C8.66703 6.88 8.22036 7.32667 7.6737 7.32667C7.12036 7.32667 6.6737 6.88 6.6737 6.32667C6.6737 5.78 7.12036 5.33333 7.6737 5.33333ZM6.9937 12C6.44703 12 6.00036 11.5533 6.00036 11.0067C6.00036 10.4533 6.44703 10.0067 6.9937 10.0067C7.54703 10.0067 7.9937 10.4533 7.9937 11.0067C7.9937 11.5533 7.54703 12 6.9937 12ZM9.3337 9.33333H5.3337C4.9657 9.33333 4.66703 9.03467 4.66703 8.66667C4.66703 8.29867 4.9657 8 5.3337 8H9.3337C9.7017 8 10.0004 8.29867 10.0004 8.66667C10.0004 9.03467 9.7017 9.33333 9.3337 9.33333ZM11.667 5.33333C11.1137 5.33333 10.667 4.88667 10.667 4.33333C10.667 3.78 11.1137 3.33333 11.667 3.33333C12.2204 3.33333 12.667 3.78 12.667 4.33333C12.667 4.88667 12.2204 5.33333 11.667 5.33333Z"
        fill={fill || "#42AB44"}
      />
    </svg>
  );
};

export { SalePriceTagIcon };
