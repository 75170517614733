import styled from "styled-components";
import { rtl } from "../../shared/styles-utils";
import { H1, H2, Span } from "../../shared/globals";

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  place-content: center;
  background-color: none;
`;

export const HeaderText = styled(H1)`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const HeadText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  place-content: center;
  text-align: center;
  padding: 60px 0 40px 0;
`;

export const Text = styled(H1)`
  color: ${({ theme }) => theme.text.default};
  font-size: 34px;
  font-weight: bold;
`;

export const BreadcrubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0;
  width: 100%;
  ${rtl("padding-right: 30px;", "padding-left: 30px;")}

  @media (min-width: 768px) {
    ${rtl("padding-right: 120px;", "padding-left: 120px;")}
  }
`;

export const NavigatorHeading = styled(H2)`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const NavIcon = styled(Span)`
  margin: 0 8px;
  transform: ${rtl("rotate(180deg)", "rotate(0deg)")};
`;

export const RequiredSpan = styled.span`
  font-size: 12px;
  color: #d21c1c;
  font-weight: 400;
  height: 0;
  margin-top: 4px;
`;
